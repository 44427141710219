import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLogger } from '../services/log';
import { useStore } from '../services/store';

const AppLogger = () => {
  const { logPageView } = useLogger();
  const { pathname, search } = useLocation();
  const { state } = useStore();

  const sessionUser = state.user;

  useEffect(() => {
    if (!sessionUser.id) return;

    logPageView();
  }, [pathname, search, sessionUser, logPageView]);

  return null; // This component doesn't render anything
};

export default AppLogger;
