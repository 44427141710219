import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateErrorMessage } from './utils';
import { useStore } from './store';

export const useAsync = (asyncFunction, { immediate } = {}) => {
  const [status, setStatus] = useState('idle');
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    (...params) => {
      setStatus('pending');
      setValue(null);
      setError(null);

      return asyncFunction(...params)
        .then((response) => {
          setValue(response);
          setStatus('success');
          return response;
        })
        .catch((error) => {
          error.message = generateErrorMessage(error);
          setError(error);
          setStatus('error');
          return Promise.reject(error.message); // Reject the promise to allow one to `.catch` the `execute` function
        });
    },
    [asyncFunction]
  );

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  const reset = () => {
    // Resets state variables (required for modals which use `useAsync` to clear error msgs, etc. on modal close)
    setStatus('idle');
    setValue(null);
    setError(null);
  };

  return { execute, status, value, error, setError, reset };
};

export const useSessionUser = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useStore();

  const isImpersonatingUser = !!state.impersonatedUser;
  const impersonateUser = async (user) => {
    navigate('/'); // Clear any search params in the URL and navigate to the home page (without this the user might be redirected to a page the impersonated user doesn't have access to)
    dispatch({ type: 'IMPERSONATE_USER', user });
  };
  const stopImpersonatingUser = () => {
    navigate('/'); // Clear any search params in the URL and navigate to the home page (without this the logged-in user's scoped org will be set to the impersonated user's scoped org)
    dispatch({ type: 'STOP_IMPERSONATING_USER' });
  };

  // Set the `sessionUser` to the logged-in user unless they're viewing the dashboard as (i.e. "impersonating") another user
  const sessionUser = isImpersonatingUser ? state.impersonatedUser : state.user;

  const isSystemAdmin = sessionUser.permissions?.find(({ role_name }) => role_name === 'sysadmin');

  const isInternalUser = sessionUser.permissions?.find(
    ({ role_name }) => role_name === 'admin' || role_name === 'sysadmin'
  );

  // We keep track of the currently selected org to scope applicable pages to as a fallback when an org id URL param is not provided
  // Ensure we check whether or not `sessionUser` represents the logged-in user or a user being impersonated when setting the scoped org (as they need to be stored independently)
  const setScopedOrgId = useCallback(
    (scopedOrgId) => {
      dispatch({
        type: isImpersonatingUser ? 'SET_IMPERSONATED_USER_SCOPED_ORG' : 'SET_USER_SCOPED_ORG',
        scopedOrgId,
      });
    },
    [dispatch, isImpersonatingUser]
  );

  /**
   * Returns `true` if one of the session user's assigned roles (for one of the orgs they're associated with) is one of the `permittedRoles` passed in.
   * (NOTE: Always return `true` when the session user is an internal user since they have view access to everything.)
   */
  const hasAccessForAnyOrg = useCallback(
    ({ permittedRoles = [] } = {}) =>
      isInternalUser ||
      sessionUser.permissions?.some(({ role_name: roleName }) => permittedRoles.includes(roleName)),
    [isInternalUser, sessionUser.permissions]
  );

  /**
   * Returns `true` if the session user's assigned role for the org associated with `orgId` is one of the `permittedRoles` passed in.
   * (NOTE: Always return `true` when the session user is an internal user since they have view access to everything.)
   */
  const hasAccessForOrg = useCallback(
    ({ orgId, permittedRoles = [] } = {}) =>
      isInternalUser ||
      sessionUser.permissions?.some(
        ({ id: orgIds, role_name: roleName }) =>
          orgIds.includes(orgId) && permittedRoles.includes(roleName)
      ),
    [isInternalUser, sessionUser.permissions]
  );

  const { isLoaded, id, email, scopedOrgId, hasRL } = sessionUser;

  return {
    isImpersonatingUser,
    impersonateUser,
    stopImpersonatingUser,
    isLoaded,
    isSystemAdmin,
    isInternalUser,
    id,
    email,
    scopedOrgId,
    setScopedOrgId,
    hasAccessForAnyOrg,
    hasAccessForOrg,
    hasAccessToRLMetrics: hasRL,
  };
};
