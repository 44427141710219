const languages = [
  { code: 'DE', name: 'German' },
  { code: 'EN', name: 'English' },
  { code: 'ES', name: 'Spanish' },
  { code: 'FR', name: 'French' },
  { code: 'IT', name: 'Italian' },
  { code: 'JA', name: 'Japanese' },
  { code: 'NL', name: 'Dutch' },
  { code: 'PT', name: 'Portuguese' },
  { code: 'RU', name: 'Russian' },
  { code: 'SV', name: 'Swedish' },
  { code: 'ZH', name: 'Chinese (Simplified)' },
  { code: 'TW', name: 'Chinese (Traditional)' },
];

const languageKeys = ['EN', 'ES', 'FR', 'DE', 'IT', 'JA', 'NL', 'PT', 'RU', 'SV', 'ZH', 'TW'];

export { languages, languageKeys };
