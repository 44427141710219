import { Form, Radio, Typography } from 'antd';
import { CodeOutlined } from '@ant-design/icons';

const { Text } = Typography;

const APIMode = ({ env, disabled, form }) => {
  const renderMode = Form.useWatch([env, 'am_settings', 'render_mode'], form);
  const apiMode = Form.useWatch([env, 'am_settings', 'api_mode'], form);
  if (renderMode !== 'api') return null;
  const radioOptions = [
    {
      label: (
        <>
          Target
          <Text
            copyable={
              disabled
                ? false
                : {
                    icon: (
                      <CodeOutlined
                        style={{
                          color: apiMode !== 'wait' ? 'gray' : undefined,
                        }}
                      />
                    ),
                    tooltips: [
                      'The message is rendered only in locations provided in the API response',
                      'Copied',
                    ],
                    text: `
                      // Listen to the event indicating the RLink script was loaded and the render criteria were met
                      // It can be added before or after the RLink script is loaded
                      window.addEventListener('bt-rlink-api-ready', function(event) {
                          // After the step above the API can be called anytime
                          const api = event.detail.api();
                          // Render the message                          
                          api.render();
                      });
                      `,
                  }
            }
          />
        </>
      ),
      value: 'wait',
    },
    {
      label: (
        <>
          Restrict
          <Text
            copyable={
              disabled
                ? false
                : {
                    icon: (
                      <CodeOutlined
                        style={{
                          color: apiMode !== 'auto' ? 'gray' : undefined,
                        }}
                      />
                    ),
                    tooltips: [
                      'The message is always rendered unless is prevented by the API response',
                      'Copied',
                    ],
                    text: `
                      // Listen to the event indicating the RLink script was loaded and the render criteria were met
                      // Important: It needs to be added before the RLink script is loaded
                      window.addEventListener('bt-rlink-api-ready', function(event) {
                        // After the step above the API can be called anytime
                          const api = event.detail.api();
                          // Prevent the message from rendering                          
                          api.prevent();
                      });
                      `,
                  }
            }
          />
        </>
      ),
      value: 'auto',
    },
  ];
  return (
    <>
      <Form.Item
        label="API Mode"
        name={[env, 'am_settings', 'api_mode']}
        tooltip={
          <div>
            <p>Determines the default behaviour of the API</p>
            <p>Check the code snippets on the options for more details</p>
          </div>
        }
      >
        <Radio.Group options={radioOptions} optionType="button" disabled={disabled} />
      </Form.Item>
    </>
  );
};

export default APIMode;
