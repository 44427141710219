import { useState, useEffect, useMemo } from 'react';
import { Tabs, Card, Form } from 'antd';
import { LanguageInput, languageKeys } from './LanguageInput';
import { sample } from 'lodash';

const createLanguageTab = (
  idx,
  language,
  env,
  onChangeHandler,
  currentLangKeys,
  viewMode,
  hardWallEnabled,
  adaptiveHardWallEnabled,
  formInstance
) => ({
  label: language,
  children: (
    <LanguageInput
      env={env}
      language={language}
      onChange={(v) => onChangeHandler(v, language)}
      existKeys={currentLangKeys}
      viewMode={viewMode}
      hardWallEnabled={hardWallEnabled}
      adaptiveHardWallEnabled={adaptiveHardWallEnabled}
      formInstance={formInstance}
    />
  ),
  key: language,
  forceRender: true,
  closable: idx === 0 ? false : true,
});

const MultiLanguageInput = ({ env, formInstance }) => {
  const viewMode = Form.useWatch([env, 'am_settings', 'view'], formInstance);
  const hardWallEnabled = Form.useWatch(
    [env, 'am_settings', 'hard_message_wall_mode_enabled'],
    formInstance
  );
  const adaptiveHardWallEnabled = Form.useWatch(
    [env, 'am_settings', 'adaptive_hard_message_wall_mode_enabled'],
    formInstance
  );

  const [currentLangKeys, setCurrentLangKeys] = useState([]);
  const [activeKey, setActiveKey] = useState('');
  const [items, setItems] = useState([]);

  const handleLanguageChange = (newLang, oldLang) => {
    setActiveKey(newLang);
    setCurrentLangKeys((prevKeys) => {
      const newKeys = prevKeys.map((lang) => (lang === oldLang ? newLang : lang));

      setItems((prevItems) =>
        prevItems.map((item, i) => {
          const lang = item.key === oldLang ? newLang : item.key;
          return createLanguageTab(
            i,
            lang,
            env,
            handleLanguageChange,
            newKeys,
            viewMode,
            hardWallEnabled,
            adaptiveHardWallEnabled,
            formInstance
          );
        })
      );

      return newKeys;
    });
  };

  const langsToAdd = useMemo(() => languageKeys.filter((v) => !currentLangKeys.includes(v)), [
    currentLangKeys,
  ]);

  useEffect(() => {
    let languages = formInstance.getFieldValue([env, 'am_settings', 'i18n']);

    // trick to open first tab for new users
    if (!languages || Object.keys(languages).length < 1) {
      languages = { EN: {} };
    }
    const currentLanguages = Object.keys(languages);
    setCurrentLangKeys(currentLanguages);

    const initialTabs = currentLanguages.map((key, idx) =>
      createLanguageTab(
        idx,
        key,
        env,
        handleLanguageChange,
        currentLanguages,
        viewMode,
        hardWallEnabled,
        adaptiveHardWallEnabled,
        formInstance
      )
    );
    setItems(initialTabs);
    setActiveKey(initialTabs[0]?.key || '');
  }, [formInstance, env]);

  useEffect(() => {
    setItems((prevItems) =>
      prevItems.map((item, i) => {
        return createLanguageTab(
          i,
          item.key,
          env,
          handleLanguageChange,
          currentLangKeys,
          viewMode,
          hardWallEnabled,
          adaptiveHardWallEnabled,
          formInstance
        );
      })
    );
  }, [viewMode, hardWallEnabled, adaptiveHardWallEnabled]);

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      add();
    } else {
      remove(targetKey);
    }
  };

  const add = () => {
    const newRandLang = sample(langsToAdd);
    if (newRandLang) {
      const newLangKeys = [...currentLangKeys, newRandLang];
      const newTab = createLanguageTab(
        items.length,
        newRandLang,
        env,
        handleLanguageChange,
        newLangKeys,
        viewMode,
        hardWallEnabled,
        adaptiveHardWallEnabled,
        formInstance
      );

      setItems((prevItems) => [...prevItems, newTab]);
      setCurrentLangKeys(newLangKeys);
      setActiveKey(newRandLang);
    }
  };

  const remove = (targetKey) => {
    setCurrentLangKeys((prevKeys) => {
      const newKeys = prevKeys.filter((v) => targetKey !== v);

      setItems((prevItems) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;

        let newPanes = prevItems.filter((item, i) => {
          if (item.key === targetKey) {
            lastIndex = i - 1;
          }
          return item.key !== targetKey;
        });

        newPanes = newPanes.map((item, i) => {
          return createLanguageTab(
            i,
            item.key,
            env,
            handleLanguageChange,
            newKeys,
            viewMode,
            hardWallEnabled,
            adaptiveHardWallEnabled,
            formInstance
          );
        });

        if (newPanes.length && newActiveKey === targetKey) {
          if (lastIndex >= 0) {
            newActiveKey = newPanes[lastIndex].key;
          } else {
            newActiveKey = newPanes[0].key;
          }
        }
        setActiveKey(newActiveKey);

        return newPanes;
      });

      return newKeys;
    });
  };

  return (
    <Card style={{ marginBottom: '24px' }}>
      <Tabs
        type="editable-card"
        onChange={onChange}
        activeKey={activeKey}
        onEdit={onEdit}
        items={items}
      />
    </Card>
  );
};

export default MultiLanguageInput;
