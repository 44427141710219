import { Card, Form } from 'antd';
import { CodeEditor } from '../..';
import cssValidator from 'w3c-css-validator';
import ButtonColorFormItem from './ButtonColorFormItem';
import FontFormItem from './FontFormItem';
import { useState } from 'react';

const tabList = [
  {
    key: 'custom_css',
    tab: 'Custom CSS',
  },
  {
    key: 'predefined_css',
    tab: 'Predefined CSS',
  },
];

const StyleInput = ({ env, formInstance }) => {
  const [activeCustomCss, setActiveCustomCss] = useState('custom_css');

  const onTabChange = (key) => {
    setActiveCustomCss(key);
  };

  const tabsContentList = {
    custom_css: (
      <Form.Item
        name={[env, 'am_settings', 'custom_css']}
        tooltip="A valid CSS "
        labelCol={{ span: 24 }}
        validateTrigger="onBlur"
        rules={[
          {
            required: false,
            validator: async (_, value) => {
              if (!value) {
                return Promise.resolve();
              }
              const { valid } = await cssValidator.validateText(value);
              return valid ? Promise.resolve() : Promise.reject('Wrong css code');
            },
            message: 'Please enter a valid css!',
          },
        ]}
      >
        <CodeEditor placeholder={'.bt-softwall {}'} height={360} language="css" />
      </Form.Item>
    ),
    predefined_css: (
      <>
        <ButtonColorFormItem env={env} form={formInstance} />
        <FontFormItem env={env} />
      </>
    ),
  };

  return (
    <Card
      tabList={tabList}
      activeTabKey={activeCustomCss}
      onTabChange={(key) => {
        onTabChange(key);
      }}
      style={{ marginBottom: '24px' }}
    >
      {tabsContentList[activeCustomCss]}
    </Card>
  );
};

export default StyleInput;
