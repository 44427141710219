import React, { useState } from 'react';
import { Form, Input, Button, List, Space, message } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const AllowListURL = ({ env, form }) => {
  const allowlistedUrlsFieldName = [env, 'am_settings', 'allowlisted_urls'];
  const addUrlsFieldName = [env, 'am_settings', 'add_urls'];
  const urls = Form.useWatch(allowlistedUrlsFieldName, form) || [];
  const inputValue = Form.useWatch(addUrlsFieldName, form) || '';

  const [showInput, setShowInput] = useState(false);

  const validateURLs = (urls) => {
    try {
      const urlPattern = /^(https?:\/\/)?([a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+)(\/[-a-zA-Z\d%_.~+]*)*$/;

      return urls.every((url) => {
        const parsedUrl = new URL(url);
        return urlPattern.test(parsedUrl.toString());
      });
    } catch (e) {
      return false;
    }
  };

  const handleApply = () => {
    if (inputValue.trim()) {
      const filteredURLs = inputValue.split('\n').filter((url) => url.trim() !== '');
      if (!validateURLs(filteredURLs)) {
        message.error('One or more URLs are invalid. Please enter valid URLs, each on a new line.');
        return;
      }
      const uniqueUrls = Array.from(new Set([...urls, ...filteredURLs]));
      form.setFieldsValue({
        [env]: { am_settings: { allowlisted_urls: uniqueUrls, add_urls: '' } },
      });
    }
    setShowInput(false);
  };

  const handleCancel = () => {
    form.setFieldValue(addUrlsFieldName, '');
    setShowInput(false);
  };

  const handleRemoveUrl = (index) => {
    const updatedURLsList = [...urls];
    updatedURLsList.splice(index, 1);
    form.setFieldValue(allowlistedUrlsFieldName, updatedURLsList);
  };

  return (
    <Form.Item
      label="Allowlist URLs"
      name={allowlistedUrlsFieldName}
      tooltip={'Allowlist URLs override other settings to prevent RL rendering on certain pages'}
    >
      <>
        {showInput ? (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Form.Item
              name={[env, 'am_settings', 'add_urls']}
              style={{ width: '100%', marginBottom: '0px' }}
            >
              <Input.TextArea
                placeholder="Enter each URL on a new line - e.g. &#13;https://domain.com/allowlist1 &#13;https://domain.com/allowlist2"
                autoSize={{ minRows: 3 }}
              />
            </Form.Item>
            <Space>
              <Button type="default" onClick={handleApply} disabled={!inputValue.trim()}>
                Apply
              </Button>
              <Button type="default" onClick={handleCancel}>
                Cancel
              </Button>
            </Space>
          </Space>
        ) : (
          <Button type="default" onClick={() => setShowInput(true)} icon={<PlusOutlined />}>
            Add
          </Button>
        )}
        {urls.length > 0 && (
          <List
            bordered
            size="small"
            style={{ marginTop: 16 }}
            dataSource={urls}
            renderItem={(url, index) => (
              <List.Item
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingRight: 6,
                }}
              >
                <span
                  style={{
                    flex: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {url}
                </span>
                <Button
                  type="link"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemoveUrl(index)}
                />
              </List.Item>
            )}
          />
        )}
      </>
    </Form.Item>
  );
};

export default AllowListURL;
