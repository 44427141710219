import { Form, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const UploadLogoFormItem = ({ name }) => {
  const url = Form.useWatch(name);
  const fileList = url
    ? [
        {
          uid: '-1',
          status: 'done',
          url,
        },
      ]
    : undefined;
  return (
    <Form.Item label="Logo" name={name} getValueFromEvent={({ file: { response } }) => response}>
      <Upload
        name="logo"
        action={`${process.env.REACT_APP_DASH_API_URL}/alternative-monetization/UploadLogo`}
        withCredentials={true}
        fileList={fileList}
        accept="image/*"
        listType="picture-card"
        maxCount={1}
      >
        {!url ? <UploadOutlined /> : null}
      </Upload>
    </Form.Item>
  );
};

export default UploadLogoFormItem;
