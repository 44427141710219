import { Card, Col, Divider, Form, Radio, message, Modal } from 'antd';
import { useState } from 'react';
import { mutate } from 'swr';
import api from '../../services/api';
import CommonForm from './CommonForm';

export const WebsiteSoftMessageWallSettings = ({ org, website, isViewOnly }) => {
  const [env, setEnv] = useState('prod_params');
  const [formInstance] = Form.useForm();

  // Hide the override option in case org is disabled
  const hideOverride = !org?.[env]?.am_settings?.enabled;

  const onUpdateWebsite = (data) => {
    mutate(
      ['/WebsiteGet', website.id],
      api
        .updateWebsite({
          id: website.id,
          ...data,
        })
        .then((response) => {
          message.success('Soft Message Wall settings successfully updated!');
          return response.org;
        })
        .catch(() => message.error('Soft Message Wall settings update failed'))
    ).then(() => formInstance.resetFields());
  };

  const update = (formData) => {
    // Use the enabled field value on the override field in case it is hidden
    // so this website settings are not overridden when org settings are added later
    if (hideOverride) {
      formData[env].am_settings.override_org = formData[env].am_settings.enabled;
    }

    onUpdateWebsite({ [env]: formData[env] });
  };

  const override_org = Form.useWatch([env, 'am_settings', 'override_org'], formInstance);
  // Disable the script loading mode option when the override is available and it's not enabled
  const shouldDisableScriptLoadingMode = !hideOverride && !override_org;

  const handleOnPromoteToProd = () => {
    Modal.confirm({
      title: 'Production settings will be overwritten. Continue?',
      okText: 'Continue',
      onOk() {
        const data = formInstance.getFieldsValue()?.[env] || {};

        // We need to save for both envs to prevent issue when we change something for the stage,
        // but the changes weren't saved before. We want to have both envs to be equal.
        onUpdateWebsite({
          prod_params: data,
          stg_params: data,
        });
      },
    });
  };

  return (
    <Card>
      <Col span={10}>
        <Radio.Group onChange={(event) => setEnv(event.target.value)} size="small" value={env}>
          <Radio.Button value="prod_params">Prod</Radio.Button>
          <Radio.Button value="stg_params">Staging</Radio.Button>
        </Radio.Group>
        <Divider />

        <CommonForm
          formInstance={formInstance}
          env={env}
          isViewOnly={isViewOnly}
          initialValue={website}
          update={update}
          hideOverride={hideOverride}
          shouldDisableScriptLoadingMode={shouldDisableScriptLoadingMode}
          org={org}
          isWebsite={true}
          onPromoteToProd={handleOnPromoteToProd}
        />
      </Col>
    </Card>
  );
};
