import React from 'react';
import { Typography, Divider } from 'antd';

const FormGroupItem = ({ children, label }) => {
  return (
    <>
      <Typography.Title level={5}>{label}</Typography.Title>
      <div style={{ paddingLeft: 10 }}>{children}</div>
      <Divider />
    </>
  );
};

export default FormGroupItem;
