import React from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from './store';

const EVENTS = {
  CLICK: 'click',
  PV: 'page_view',
  MOUSE_OVER: 'mouse_over',
  DOWNLOAD: 'download',
};

const useLogger = () => {
  const controller = new AbortController();
  const signal = controller.signal;

  const { pathname, search } = useLocation();
  const { state } = useStore();

  const sessionUser = state.user || {};
  const roles = (sessionUser.permissions || []).map((p) => p.role_name).join(',');

  const baseURL = process.env.REACT_APP_DASH_API_URL;
  if (!baseURL) {
    console.error('REACT_APP_DASH_API_URL is not defined');
  }

  const eventsUrl = React.useMemo(() => {
    return baseURL ? new URL(`${baseURL}/events/dash`) : null;
  }, [baseURL]);

  const log = React.useCallback(
    ({ event, element, meta = {} }) => {
      if (!eventsUrl) return;

      const body = {
        event,
        element,
        meta: JSON.stringify(meta),
        url: `${pathname}${search}`,
        user_id: Number(sessionUser.id || 0),
        user_role: roles,
      };

      return fetch(eventsUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
        signal,
      }).catch((error) => {
        if (error.name !== 'AbortError') {
          console.error('Logging error:', error);
        }
      });
    },
    [eventsUrl, pathname, sessionUser.id, roles, search]
  );

  return {
    log,
    logClick: (element, meta) => log({ event: EVENTS.CLICK, element, meta }),
    logPageView: () => log({ event: EVENTS.PV, element: `${pathname}${search}` }),
    logMouseOver: (element, meta) => log({ event: EVENTS.MOUSE_OVER, element, meta }),
    logDownload: (element, meta) => log({ event: EVENTS.DOWNLOAD, element, meta }),
  };
};

export { useLogger, EVENTS };
